import React from 'react'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '../components/layout'
import Seo from '../components/seo'

const ContactPage = ({ data }) => {
    const { frontmatter, html } = data.file.childMarkdownRemark
    return (
        <Layout footerLock>
            <Seo title="Contact" />

            <section className="hero is-bold is-primary">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title">{frontmatter.title}</h1>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <div className="box">
                                <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
                                {frontmatter.buttons.map(({ button }, i) => (
                                    <a className="button is-ghost" href={button.link} target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={[button.iconCol, button.icon]} />{" "}
                                        {button.text}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ContactPage

export const pageQuery = graphql`
    {
        file(name: {eq: "contact"}) {
            childMarkdownRemark {
                html
                frontmatter {
                    title
                    buttons {
                        button {
                            text
                            link
                            iconCol
                            icon
                        }
                    }
                }
            }   
        }
    }
`